import { Link } from "gatsby";
import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "70vh",
        maxWidth: "25rem",
        margin: "auto"
      }}
    >
      <h1>404</h1>
      <h2>Sorry: Page not found</h2>
      <p>
        The page you are looking for might have been removed had its name changed or is temporarily
        unavailable
      </p>
      <Link to="/" className="primary-link-btn">
        Go to homepage
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
